import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "menu-item px-3" }
const _hoisted_2 = { class: "card card-flush" }
const _hoisted_3 = {
  key: 0,
  class: "card-body pt-0"
}
const _hoisted_4 = { class: "menu-item px-3" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_QuickAction = _resolveComponent("QuickAction")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_DateTimeFormat = _resolveComponent("DateTimeFormat")!
  const _component_UserStatus = _resolveComponent("UserStatus")!
  const _component_KtDatatable = _resolveComponent("KtDatatable")!
  const _component_Entities = _resolveComponent("Entities")!
  const _component_FormUser = _resolveComponent("FormUser")!
  const _component_BaseModal = _resolveComponent("BaseModal")!
  const _component_InviteUserForm = _resolveComponent("InviteUserForm")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Entities, {
      "enable-filter": false,
      "view-card": false,
      "view-table": true,
      label: "Users",
      items: _ctx.userPage.total,
      "display-view": false
    }, {
      actions: _withCtx(() => [
        _createVNode(_component_QuickAction, null, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("a", {
                href: "javascript:void(0);",
                class: "menu-link px-3",
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.invite && _ctx.invite(...args)))
              }, "Invite User")
            ])
          ]),
          _: 1
        })
      ]),
      table: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          (_ctx.userPage)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(_component_KtDatatable, {
                  "table-data": _ctx.userPage.data,
                  "table-header": _ctx.tableHeader,
                  "enable-items-per-page-dropdown": true,
                  "current-page": _ctx.filter.paginationPage,
                  "rows-per-page": _ctx.filter.paginationSize,
                  onItemsPerPageChange: _ctx.handleUpdateSize,
                  onCurrentChange: _ctx.handleUpdatePage,
                  total: _ctx.userPage.total
                }, {
                  "cell-fullName": _withCtx(({ row: user }) => [
                    _createVNode(_component_router_link, {
                      to: `/users/`+ user.id
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(user.fullName), 1)
                      ]),
                      _: 2
                    }, 1032, ["to"])
                  ]),
                  "cell-lastLogin": _withCtx(({ row: user }) => [
                    _createVNode(_component_DateTimeFormat, {
                      date: user.lastLogin,
                      time: true
                    }, null, 8, ["date"])
                  ]),
                  "cell-status": _withCtx(({ row: user }) => [
                    _createVNode(_component_UserStatus, {
                      status: user.status
                    }, null, 8, ["status"])
                  ]),
                  "cell-actions": _withCtx(({ row: user}) => [
                    _createVNode(_component_QuickAction, { size: "w-150px" }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_4, [
                          (user.status ==='ACTIVATED')
                            ? (_openBlock(), _createElementBlock("a", {
                                key: 0,
                                href: "javascript:void(0);",
                                class: "menu-link px-3",
                                onClick: ($event: any) => (_ctx.onUpdateStatus(user, 'DEACTIVATED'))
                              }, "Deactivate", 8, _hoisted_5))
                            : _createCommentVNode("", true),
                          (user.status ==='DEACTIVATED')
                            ? (_openBlock(), _createElementBlock("a", {
                                key: 1,
                                href: "javascript:void(0);",
                                class: "menu-link px-3",
                                onClick: ($event: any) => (_ctx.onUpdateStatus(user, 'ACTIVATED'))
                              }, "Activate", 8, _hoisted_6))
                            : _createCommentVNode("", true)
                        ])
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 1
                }, 8, ["table-data", "table-header", "current-page", "rows-per-page", "onItemsPerPageChange", "onCurrentChange", "total"])
              ]))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 1
    }, 8, ["items"]),
    _createVNode(_component_BaseModal, {
      id: "modalUser",
      name: "User",
      ref: "userForm"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_FormUser, {
          user: _ctx.state.user,
          onClose: _ctx.onSaveUser
        }, null, 8, ["user", "onClose"])
      ]),
      _: 1
    }, 512),
    _createVNode(_component_BaseModal, {
      id: "inviteUser",
      name: "Invite New User",
      ref: "inviteNewUserRef"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_InviteUserForm, { onInvited: _ctx.onInvited }, null, 8, ["onInvited"])
      ]),
      _: 1
    }, 512)
  ], 64))
}