
import {computed, defineComponent, reactive, ref} from "vue";
import Entities from "@/components/base/common/Entities.vue";
import {PinaFilterObjects} from "@/core/composite/composite";
import KtDatatable from "@/components/kt-datatable/KTDatatable.vue";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";
import QuickAction from "@/components/base/action/QuickAction.vue";
import BaseModal from "@/components/base/modal/BaseModal.vue";
import FormUser from "@/views/user/FormUser.vue";
import UserService from "@/core/services/UserService";
import Swal from "sweetalert2";
import {useUserStore} from "@/store/user.store";
import UserStatus from "@/views/user/UserStatus.vue";
import InviteUserForm from "@/views/user/InviteUserForm.vue";

export default defineComponent({
  name: "Users",
  components: {InviteUserForm, UserStatus, FormUser, BaseModal, QuickAction, DateTimeFormat, KtDatatable, Entities},
  props: {
    companyId: {type: String},
  },

  setup(props) {
    const store = useUserStore();

    const state = reactive({
      user: {}
    });
    const filterObjects = ref({companyId: props.companyId});
    const userPage = computed(() => store.page);
    const tableHeader = ref([
      {
        name: "Name",
        key: "fullName",
        sortable: true,
      },
      {
        name: "Last Login",
        key: "lastLogin",
        sortable: true,
      },
      {
        name: "Status",
        key: "status",
        sortable: true,
      },
      {
        name: "",
        key: "actions",
        sortable: true,
      },
    ]);
    return {
      userPage,
      tableHeader,
      state,
      ...PinaFilterObjects(store, filterObjects.value, []),
    }
  },
  methods: {
    invite() {
      const modal = this.$refs.inviteNewUserRef as typeof BaseModal
      modal.showBaseModal();
    },
    onUpdate(user) {
      this.state.user = user;
    },
    onUpdateStatus(user, status) {
      UserService.updateStatus(user.id, status).then(() => {
        this.paginationDataLoad()
      })
    },
    onSaveUser() {
      const baseModal = this.$refs.userForm as typeof BaseModal
      baseModal.hideBaseModal()
      this.paginationDataLoad()
    },
    onInvited() {
      this.paginationDataLoad();
      const modal = this.$refs.inviteNewUserRef as typeof BaseModal;
      modal.hideBaseModal();
    },
    deleteUser(user) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        showLoaderOnConfirm: true,
        preConfirm: async () => {
          await UserService.delete(user.id).then(() => {
            this.paginationDataLoad();
          })
        }
      })
    }
  }
})
